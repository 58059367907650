import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import client from '@/api/client'
import type { Booking, BookingExtraExpense } from '@/models/your-bookings'

type WaitingTypeType = {
    hours: number | null;
    minutes: number | null;
}

export function useWaitingTime(id: number) {
    const getUrl = `/v2/bookings/${id}/waiting-time`
    const { error } = useApiErrors(getUrl)
    const { isPending } = useApiRequests(getUrl)

    const chargeUrl = `/v2/bookings/${id}/charge-waiting-time`
    const { error: chargeError } = useApiErrors(chargeUrl)
    const { isPending: isChargePending } = useApiRequests(chargeUrl)

    return {
        getWaitingTime: () => client.get(getUrl).then<BookingExtraExpense[]>(r => r.data),
        saveWaitingTime: (data: WaitingTypeType) => client.post(getUrl, data).then<Booking>(r => r.data),
        chargeWaitingTime: () => client.post(chargeUrl).then<Booking>(r => r.data),
        error,
        isPending,
        chargeError,
        isChargePending,
    }
}

